import React, { Component } from 'react';
import classNames from 'classnames';

import { callPlayer, getSDK } from 'react-player/lib/utils';

const SDK_URL = 'https://embed.voomly.com/embed/embed-build.js';
const SDK_GLOBAL = 'voomlyEmbedPlayerApp';

export function extractId(str) {
    try {
        const url = new URL(str);

        return url.pathname.split('/')[2];
    } catch {
        return false;
    }
}
export default class Voomly extends Component {
  static displayName = 'Voomly';
  static canPlay = (url) =>
    /\.voomly\.com\/v\/([a-zA-Z0-9_-]+)$/.test(url);
  static forceLoad = true; // Prevent checking isLoading when URL changes
  callPlayer = callPlayer;
  duration = null;
  currentTime = null;
  secondsLoaded = null;

  componentDidMount() {
    this.props.onMount && this.props.onMount(this);
  }

  // Proxy methods to prevent listener leaks
  onReady = (...args) => {
    return this.props.onReady(...args);
  };
  //onReady = console.log;
  onPlay = (...args) => this.props.onPlay(...args);
  onBuffer = (...args) => this.props.onBuffer(...args);
  onBufferEnd = (...args) => this.props.onBufferEnd(...args);
  onPause = (...args) => this.props.onPause(...args);
  onEnded = (...args) => this.props.onEnded(...args);
  onError = (...args) => this.props.onError(...args);
  onEnablePIP = (...args) => this.props.onEnablePIP(...args);
  onSeek = (...args) => this.props.onSeek(...args);

  load(url) {
    this.duration = null;
    const id = extractId(this.props.url)
    getSDK(SDK_URL, 'voomlyEmbedPlayerPreloader').then((voomlyEmbedPlayerPreloader) => {
      voomlyEmbedPlayerPreloader.onAttached(id, ({ api: playerAPI }) => {
        this.player = playerAPI;
        this.player.onAPIReady(() => {
          this.player.onReady(() => {
            //this.player.addEventListener('loadeddata', this.onReady);
            this.player.onPlay(this.onPlay);
            //this.player.addEventListener('waiting', this.onBuffer);
            //this.player.addEventListener('playing', this.onBufferEnd);
            this.player.onPause(this.onPause);
            this.player.onSeeked(this.onSeek);
            this.player.onEnd(this.onEnded);
          });
        });
      });      
      //this.player.addEventListener('error', this.onError);
    }, this.props.onError);
  }

  refreshDuration() {
    this.player?.getDuration().then((duration) => {
      this.duration = duration;
    });
  }

  play() {
    try{
        this.player?.play()
    }catch(e){console.log(e)}
  }

  pause() {
    this.player?.pause();
  }

  stop() {
    this.player?.pause();
  }

  seekTo(seconds) {
    this.player?.seek({time: seconds});
  }

  setVolume(fraction) {
    this.player?.changeVolume({volume: fraction});
  }

  setLoop(loop) {
    //Not Supported
    return null;
  }

  setPlaybackRate(rate) {
    this.player?.changeSpeed({speed: rate});
  }

  mute = () => {
    this.player?.mute();
  };

  unmute = () => {
    this.player?.unmute();
  };

  getDuration() {
    //Not Supported
    return null;
  }

  getCurrentTime() {
    //Not Supported
    return null;
  }

  getSecondsLoaded() {
    //Not Supported
    return null;
  }

  ref = (container) => {
    this.container = container;
  };

  render() {
    const { display, controls, poster } = this.props;

    const id = extractId(this.props.url)

    return (
        <div style = {{
          position: 'relative'
        }}>
          <div className={classNames('voomly-embed', this.props.className)} data-id={id} data-ratio="1.777778" data-type="v" data-skin-color="rgba(34,34,34,1)" data-shadow="" style={{
            width: '100%',
            aspectRatio: '16/9',
            background: 'linear-gradient(45deg, rgb(142, 150, 164) 0%, rgb(201, 208, 222) 100%)',
            borderRadius: '10px'
          }} ref={this.ref}></div>
        </div>
    );
  }
}