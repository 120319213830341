import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody, CardHeader, CardText, Col, Container, Row} from 'reactstrap';
import {getSingleDate} from 'lib/time';
// import CommentsThreaded from 'components/CommentsThreaded'; //
import {faBookmark} from '@fortawesome/pro-regular-svg-icons/faBookmark';
import {faHeart} from '@fortawesome/pro-regular-svg-icons/faHeart';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import {faBookmark as faBookmarkSolid} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {actions} from 'modules/user';
import Recipe from '../Recipe';
import RecipeCard from '../RecipeCard';
import GeneralModal from 'components/GeneralModal';
import Markdown from 'routes/Products/components/Markdown';
import List from 'components/List';
import FullPageLayout from '../FullPageLayout';
import VideoModal from '../VideoModal';
import Header from './components/Header';
import LiveVideoContainer from './components/LiveVideoContainer';
import Carousel from 'routes/Products/components/CarouselV3';
import AudioModal from '../AudioModal';
import AdvancedPackageCTA from '../../products/EmpowermentPackageV2/components/AdvancedPackageCTA';
import FacebookCommentsSlideOut from '../FacebookCommentsSlideOut';
import {actions as userActions} from 'modules/user';
import {ForumWrapper} from 'components/ForumWrapper';
const previewVideoLink = 'previewVideoLink';
//helper components
export const ShowVideo = ({video, showVideoModal, toggleModal}) =>
  video ? (
    <React.Fragment>
      <VideoModal
        videoUrl={video.data.url}
        displayProps={video.displayProps}
        showModal={showVideoModal}
        toggleModal={toggleModal}
      />

      {video.data.description && false ? (
        <Row className="mb-1">
          <Col>
            <Markdown source={video.data.description} className="text-light" />
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  ) : null;

@connect(state => ({user: state.user, application: state.application}), {
  refreshToken: userActions.refreshToken,
  revokeDiscourseAPIKey: userActions.revokeDiscourseAPIKey
})
class VideoResourcePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.productInPath = location.pathname.split('/')[2];
    this.state = {
      showModal: false, //for the recipe modal
      showVideoModal: !!this.props.showVideoModal, //for the video modal
      showVideoVariationModal: false, //for the video variation modal
      showAudioModal: false,
      bookmarked: false,
      liked: false,
      showComments: typeof this.props.showComments !== 'undefined' ? this.props.showComments : true, //default is to show comments
      owned: this.props.owned,
      bioInModal: null,
      bioModalIsOpen: false
    };
    if (!this.props.data || !this.props.data.time) {
      this.isPublishedAndReleased = true;
    } else {
      this.isPublishedAndReleased =
        this.props.isLive || (this.props.data && this.props.data.time && new Date(this.props.data.time) <= new Date());
    }

    //used for scrolling to comments section on notification links
    this.comments = React.createRef();
    this.video =
      this.isPublishedAndReleased && this.props.Children && this.props.Children.find(child => child.type === 'video');
    this.hasVideo = !!this.video;

    if (this.props && this.props.data && this.props.data[previewVideoLink] && !this.hasVideo)
      this.video = {title: 'preview', data: {url: this.props.data[previewVideoLink]}}; //replace video url with preview url
    //used for the live brodcast video, the 2nd video child is the broadcast replay
    this.videoVariation =
      this.isPublishedAndReleased &&
      this.props.Children &&
      this.props.Children.find(
        child => child.type === 'video' && child.data.text && child.data.text.includes('Broadcast Replay')
      );
    this.hasVideoVariation = !!this.videoVariation;

    this.downloads = this.props.Children && this.props.Children.filter(child => child.type === 'download');
    this.audio =
      this.isPublishedAndReleased &&
      this.downloads.find(item => (item && item.data && item.data.type == 'MP4') || item.data.type == 'MP3');
    this.hasAudio = !!this.audio;
    this.bios = (this.props.Children && this.props.Children.filter(child => child.type === 'bio')) || [];
    this.recipe = this.props.Children && this.props.Children.find(child => child.type === 'recipe');
    this.isVimeo = this.video && this.video.data.url.includes('vimeo');
    this.childLists = this.props.Children && this.props.Children.filter(child => child.type === 'list'); //some VR pages have 2 carousels (2 lists) of linked VRs
    this.toggleModal = this.toggleModal.bind(this);
    this.upgradeSlug = this.props.upgradeSlug;
    this.description = this.props.data.description || this.props.data.text || '';
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.test) {
      this.getState();
    }
  }
  toggleModal = ({name = 'showModal'}) => {
    this.setState({[name]: !this.state[name]});
  };
  //used to show the bio modal
  handleBiosNav = (legacy, legacy2, slug) => {
    //find the bio that was clicked
    const currentBio = this.bios.find(item => item.slug === slug);
    //set state for current bio
    //open modal
    if (currentBio) {
      this.setState({bioInModal: currentBio}, this.toggleModal({name: 'bioModalIsOpen'}));
    } else {
      console.log('Error: bio not found');
    }
  };
  async getState() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({...this.state, ...savedData});
    } else {
      // No saved values - do something?
    }
  }

  postState = newState => {
    this.props.post(this.id, newState);
  };

  saveState = stateItem => {
    const newState = {...this.state, ...stateItem};
    this.props.post(this.id, newState);
    this.setState({...newState});
  };

  toggleLiked = () => this.saveState({liked: !this.state.liked});
  toggleBookmarked = () => this.saveState({bookmarked: !this.state.bookmarked});
  //scroll to comments section, passed to commentsthreaded
  scrollToContent = () => {
    //timout used to ensure the reference exists when it is called by child
    setTimeout(() => {
      const offSet = this.comments.current.offsetTop;
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  };

  renderWide = () => {
    const {bookmarked, liked} = this.state;

    return (
      <Col sm={4}>
        <div style={{paddingLeft: '12px'}}>
          <div onClick={this.toggleLiked} style={{cursor: 'pointer', minWidth: '190px'}}>
            <FontAwesomeIcon style={{color: '#F5515F', marginRight: '8px'}} icon={liked ? faHeartSolid : faHeart} />
            <span>Favorite this content</span>
          </div>
          <div onClick={this.toggleBookmarked} style={{cursor: 'pointer', minWidth: '190px'}}>
            <FontAwesomeIcon
              style={{marginLeft: '3px', marginRight: '9px'}}
              icon={bookmarked ? faBookmarkSolid : faBookmark}
            />
            <span>Bookmark this page</span>
          </div>
        </div>
      </Col>
    );
  };

  downloadIcon = type => {
    switch (type) {
      case 'MP3':
        return ['far', 'file-audio'];
      // case 'PDF':
      //   return ['far', 'file-pdf'];
      case 'PDF':
        return ['far', 'cloud-download'];
      case 'VIDEO':
        return ['far', 'cloud-download'];
      default:
        return ['far', 'cloud-download'];
    }
  };

  render() {
    const {
      customPlayButton,
      customPreviewButton,
      data: {time, description, previewVideoLink, img},
      displayProps,
      expandBios = false,
      handleUpgradeModal,
      handleNav,
      hideSpacer,
      hideTitle,
      hideTimer,
      hideUpgrade,
      isActionHour,
      layoutMinHeight,
      layoutPosition,
      logoImg,
      slug,
      title,
      upgradeSlug,
      videoProgressReport, //used to report video being finished
      WrapperTag = Container,
      user
    } = this.props;

    const {
      subtitle,
      image: {url}
    } = displayProps;
    const showComments = typeof this.props.showComments !== 'undefined' ? this.props.showComments : true;
    const names = this.bios.map(e => e.title);
    let nameStr = '';
    if (names) {
      names.push(names.splice(-2).join(' & '));
      nameStr = names.join(', ');
    }
    const videoResourceTitle =
      isActionHour && this.bios
        ? `Action Hour${nameStr ? ' with ' + nameStr : ''}${time ? ': ' + getSingleDate(time) : ''}`
        : title;
    // let subtitle = title.split('Action Hour:').length > 1 ? title.split('Action Hour:')[1] : title;

    return (
      <FullPageLayout minHeight={layoutMinHeight} position={layoutPosition}>
        {showComments ? <FacebookCommentsSlideOut commentFeed={{url: 'https://summit.foodrevolution.org/broadcasts/#frs24'}} /> : null}
        {this.props.isLive ? (
          <LiveVideoContainer
            videoUrl={this.video.data.url}
            commentFeed={{url: 'https://summit.foodrevolution.org/broadcasts/#frs24'}}
            title={title}
            subtitle={subtitle}
            description={this.description}
          />
        ) : (
          <Header
            title={title}
            subtitle={subtitle}
            description={this.description}
            backgroundImg={url}
            logoImg={logoImg}
            speakersString={nameStr}
            showModal={() => this.toggleModal({name: 'showVideoModal'})}
            showAudioModal={() => this.toggleModal({name: 'showAudioModal'})}
            showVideoVariationModal={() => this.toggleModal({name: 'showVideoVariationModal'})}
            disablePreviewVideo={this.state.showVideoModal || this.state.showAudioModal}
            bios={this.bios}
            downloads={this.downloads}
            video={this.video}
            videoVariation={this.videoVariation}
            upgradeSlug={upgradeSlug}
            handleUpgradeModal={handleUpgradeModal}
            owned={this.state.owned}
            enableBackNav
            previewVideoLink={previewVideoLink}
            customPlayButton={customPlayButton}
            customPreviewButton={customPreviewButton}
            hasVideo={this.hasVideo}
            hasVideoVariation={this.hasVideoVariation}
            hasAudio={this.hasAudio}
            date={time}
            unlockableProductName={this.props.unlockableProductName}
            hideTimer={hideTimer}
          />
        )}
        {/* LOW BANDWIDTH MODE, JUST PLAY AUDIO */}
        {this.hasAudio ? (
          <AudioModal
            audioUrl={this.audio.data.url}
            displayProps={this.audio.displayProps.image ? {...this.audio.displayProps} : {...displayProps}} //just for audio image
            showModal={this.state.showAudioModal}
            toggleModal={() => this.toggleModal({name: 'showAudioModal'})}
            isAudioOnly
          />
        ) : null}

        <ShowVideo
          video={this.video}
          showVideoModal={this.state.showVideoModal}
          toggleModal={() => this.toggleModal({name: 'showVideoModal'})}
        />
        {this.videoVariation ? (
          <ShowVideo
            video={this.videoVariation}
            showVideoModal={this.state.showVideoVariationModal}
            toggleModal={() => this.toggleModal({name: 'showVideoVariationModal'})}
          />
        ) : null}

        {this.childLists.map((list, index) => (
          <Carousel
            cards={list.Children || []}
            customStyle={{backgroundColor: 'black', background: 'black', opacity: '.9'}}
            title={list.displayProps.title}
            linkTo=""
            noDescription
            handleNav={handleNav}
          />
        ))}

        {this.props.list && this.props.list ? (
          <Carousel
            cards={this.props.list}
            customStyle={{backgroundColor: 'black', background: 'black', opacity: '.9'}}
            title="All Episodes"
            linkTo=""
            noDescription
            selectedItem={this.id}
            handleNav={handleNav}
            onReplay={[]}
            live={[]}
          />
        ) : (
          ''
        )}
        {!this.state.owned && !hideUpgrade ? (
          <AdvancedPackageCTA openModal={() => handleUpgradeModal({upgradeSlug})} />
        ) : null}
        {/* V1 below  */}
        {this.bios.length > 0 ? (
          expandBios ? (
            this.bios.map(({data: {img, title, description}}) => (
              <Container>
                <Row className="d-flex align-middle text-white px-sm-5 my-5 align-items-center">
                  {img ? (
                    <Col xs={12} md={3} className="px-lg-5 pb-5 pb-sm-0">
                      <div className="userAvatar" style={{maxWidth: '200px', width: 'auto', height: 'auto '}}>
                        <img src={img} alt={title || ''} />
                      </div>
                    </Col>
                  ) : null}
                  {description ? (
                    <Col xs={12} md={img ? 9 : 12} className="px-5">
                      <Markdown source={description} />
                    </Col>
                  ) : null}
                </Row>
              </Container>
            ))
          ) : (
            <Carousel
              cards={this.bios}
              customStyle={{backgroundColor: 'black', background: 'black', opacity: '.9'}}
              title="Speakers in this Episode"
              linkTo=""
              noDescription
              variant="avatar"
              handleNav={this.handleBiosNav}
            />
          )
        ) : null}
        {false && this.downloads.length ? (
          <React.Fragment>
            <Row>
              <Col className="pb-4">
                <h5>Downloads</h5>
              </Col>
            </Row>
            <List contentItems={this.downloads} />
          </React.Fragment>
        ) : null}
        {/* RECIPES
        show recipe card if video has recipe, toggle modal with recipe in it on click */}
        {this.recipe && false && (
          <Row>
            <Col {...{xs: 12, sm: 6, md: 4}} className="py-5 summary d-print-none" onClick={this.toggleModal}>
              <RecipeCard disableRouter={true} {...this.recipe} className="h-100" />
            </Col>
          </Row>
        )}
        {/* modal for showing a recipe */}
        <GeneralModal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          component={() => {
            return <Recipe {...this.recipe} />;
          }}
        />
        {/* modal for showing a bio */}
        <GeneralModal
          isOpen={this.state.bioModalIsOpen}
          toggle={() => this.toggleModal({name: 'bioModalIsOpen'})}
          component={() => {
            const {
              image: {url, alt},
              title,
              description
            } = this.state.bioInModal.displayProps;

            return (
              <>
                <Row className="d-flex align-items-center">
                  <Col xs={12} md={3}></Col>
                  <Col xs={12} md={url ? 9 : 12}>
                    <h4>{title}</h4>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  {url ? (
                    <Col xs={12} md={3}>
                      <div className="userAvatar">
                        <img src={url} alt={title || ''} />
                      </div>
                    </Col>
                  ) : null}
                  {description ? (
                    <Col xs={12} md={url ? 9 : 12}>
                      <Markdown source={description} />
                    </Col>
                  ) : null}
                </Row>
              </>
            );
          }}
        />
        {this.recipe && false && (
          <div className="d-none d-print-block">
            <Recipe {...this.recipe} />
          </div>
        )}
        {showComments && slug && slug.includes('ask-the-experts') ? (
          <div className="d-print-none mt-5 mx-5 forumWrapper">
            <Row>
              <Col>
                <ForumWrapper
                  product={this.productInPath}
                  scrollToMe={this.scrollToContent}
                  slug={slug}
                  pageTitle={title}
                  passedReactRef={this.highlightedComment}
                  user={user}
                  refreshToken={this.props.refreshToken}
                  revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                  forumType="ContentItem"
                />
              </Col>
            </Row>
          </div>
        ) : null}
        {/* spacer */}
        {!hideSpacer ? <div style={{height: '100px', width: '100%', background: 'black', opacity: '.9'}} /> : ''}
      </FullPageLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data)),
  refreshToken: actions.refreshToken,
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoResourcePage);
